import CountUp from 'react-countup'

import React, { useState } from 'react'
import ScrollTrigger from 'react-scroll-trigger'
import './counter.css'



const CounterUpPage = () => {
    const [counterOn, setCounterOn] = useState(false)
    return (
        <div >
            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)} >
                <div className="counter_sec">
                    <div>
                        <h6> {counterOn && <CountUp start={0} end={50} duration={2} delay={0} />}
                            + </h6>
                        <p>Projects</p>
                    </div>
                    <div>
                        <h6> {counterOn && <CountUp start={0} end={50} duration={2} delay={0} />}+</h6>
                        <p>Clients</p>
                    </div>
                    <div>
                        <h6>{counterOn && <CountUp start={0} end={50} duration={2} delay={0} />}+</h6>
                        <p>Collaborations</p>
                    </div>
                    <div>
                        <h6>{counterOn && <CountUp start={0} end={50} duration={2} delay={0} />}+</h6>
                        <p>professionals</p>
                    </div>
                </div>
            </ScrollTrigger>

        </div>
    )
}
export default CounterUpPage
