// import Home from "./components/Home";
// import Contact from "./components/Contact";
import Home from "./components/Home"
import Contact from "./components/Contact"
import Services from "./components/Services";
import About from "./components/About";
import ScrollToTop from "./components/ScrollToTop";
import { Switch, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/About">
          <About />
        </Route>
        <Route path="/Services">
          <Services />
        </Route>
        <Route path="/Contact">
          <Contact />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
