import React from 'react'
import './Navbar.css'
import logo from '../images/yammco.png'
import { NavLink } from 'react-router-dom'
import { FaBars } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { useState } from 'react'




function Navbar() {
  const [Mobile, setMobile] = useState(false)
  return (
    <div className='Navbar__container'>

      <div id='nav_content'>

        <NavLink to="/">  <img className='navbar_logo' src={logo} alt="" /></NavLink>
        <div className={Mobile ? "links-mobile" : "links"} onClick={() => setMobile(false)}>
          <li><NavLink to="/">Home</NavLink></li>
          <li><NavLink to="/services">Services</NavLink></li>
          <li><NavLink to="/about">About</NavLink></li>
          <li><NavLink to="/contact">Contact</NavLink></li>

        </div>
        <button className='mobile-menu-bar' onClick={() => setMobile(!Mobile)}>
          {Mobile ? <ImCross /> : <FaBars />}
        </button>

      </div>
    </div >
  )
}

export default Navbar