import React from 'react'
import Navbar from './Navbar'
import './Services.css'
import { BiAnalyse } from 'react-icons/bi'
import { FaRegHandshake } from 'react-icons/fa'
import { MdTipsAndUpdates } from 'react-icons/md'
import { AiOutlineFileDone } from 'react-icons/ai'
import Myfooter from './Myfooter'
import eventimg from '../images/event.png'
import enterimg from '../images/entertainment.png'
import markimg from '../images/marketing.jpg'
import digimg from '../images/digital.png'



const Services = () => {


  return (

    <div>
      <div className='services_first'>
        <Navbar />
        <h1 id='services_heading'>SERVICES</h1>
      </div>
      <div className="small_boxes">
        <h3 id='sm_box_heading' >How we work</h3>
        <div className="boxes">

          <div className="box1">
            <div className='icons'>
              <BiAnalyse /> <h5>Analyze</h5>
            </div>
            <p>We utilize creative and customized methods that tailor our work to the client’s environment to the maximum.</p>
          </div>
          <div className="box2">
            <div className='icons' >
              <FaRegHandshake /> <h5>Advise</h5>
            </div>
            <p>If you find out when and where your business needs to go and how to get there – then the real progress is made.</p>
          </div>
          <div className="box3">
            <div className='icons'>
              <MdTipsAndUpdates /> <h5>Strategy</h5>
            </div>
            <p>We deliver business results via hands-on execution and leading teams through the complex change.</p>
          </div>
          <div className="box4">
            <div className='icons'>
              <AiOutlineFileDone /> <h5>Result</h5>
            </div>
            <p>We provide valuable guidance and support in the development, which help you run a successful business.</p>
          </div>
        </div>
      </div>
      <div className="services_sec">

        <div id='ser_heading'>
          <h6 >What We Offer</h6>
          <h3>~Explore Our Solutions~</h3>
        </div>

        <div className="glass">
          <div id='services_box'>

            <img id='img_ser' src={enterimg} alt="" />
            <div>
              <h3>Entertainments & Arts</h3>
              <p>Artist and Model Sourcing, Production Design and Execution, Make Up artists and Stylists, writers and content creators, casting, and line production. Production of live shows, events, TV, Cinema, Online, and Radio programming.  Line Production for Movies including location, permissions, and approvals. Casting for models, actors, singers, writers, and other unit members  Production Equipment including cameras, lighting, editing, set design etc.  Stage and Set up for live shows including design, execution, and team for concerts, events, etc.</p>
            </div>
          </div>
        </div>
        <div className="glass">
          <div id='services_box'>
            <div>
              <h3>Digital Services</h3>
              <p>Make your campaign a digital success with our Inﬂuencer marketing, blogger management and media planning services! Social Media and Community Management, Blogger Outreach Program  Inﬂuencer Marketing, Blogs and Content Management, Media Planning  Performance Marketing.</p>
            </div>
            <img id='img_ser' src={digimg} alt="" />
          </div>
        </div>
        <div className="glass">
          <div id='services_box'>

            <img id='img_ser' src={eventimg} alt="" />
            <div>
              <h3>Events & Exhibhitions</h3>
              <p>Make your campaign a digital success with our Inﬂuencer marketing, blogger management and media planning services! Social Media and Community Management, Blogger Outreach Program,  Inﬂuencer Marketing, Blogs and Content Management, Media Planning and  Performance Marketing.</p>
            </div>
          </div>
        </div>
        <div className="glass">
          <div id='services_box'>
            <div>
              <h3>Markting & PR</h3>
              <p>Brand Strategy and Direction, Brand Activations, Shopper and Trade Marketing, Promotions and Sampling.
                Press release and content generation. Lead generation with top magazine, newspapers, radio, television, online portals, and inﬂuencers. Booking slots and coordinating with media outlets. Campaign promotion and execution. Launch events and in-store activations. Brand strategy and planning.</p>
            </div>
            <img id='img_ser' src={markimg} alt="" />
          </div>
        </div>


      </div>
      <Myfooter />
    </div>

  )
}




export default Services