import React from 'react'
import './Myfooter.css'
import { NavLink } from 'react-router-dom'
import { AiFillInstagram } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { BsTwitter } from 'react-icons/bs'
import { FiDribbble } from 'react-icons/fi'
import { ImMail4 } from 'react-icons/im'
import { HiLocationMarker } from 'react-icons/hi'
import { AiFillPhone } from 'react-icons/ai'


const Myfooter = () => {
  return (
    <div>
      <footer>
        <div className='waves'>
          <div className="wave" id='wave1'></div>
          <div className="wave" id='wave2'></div>
          <div className="wave" id='wave3'></div>
          <div className="wave" id='wave4'></div>
        </div>
        <div className='social_icons'>
          <li><a href="https://www.instagram.com/y.am.co/"><AiFillInstagram /></a></li>
          <li><a href=""><BsFacebook /></a></li>
          <li><a href="https://twitter.com/YAM__company"><BsTwitter /></a></li>
          <li><a href="https://dribbble.com/YAM_co"><FiDribbble /></a></li>
        </div>
        <div className="menu">
          <div className="links">
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/services">Services</NavLink></li>
            <li><NavLink to="/about">About</NavLink></li>
            <li><NavLink to="/contact">Contact</NavLink></li>
          </div>
        </div>
        <div className="infos">
          <h6><ImMail4 />sales@yammco.com</h6>
          <h6><HiLocationMarker />Vesla Motors Building - E 11 - UAE</h6>
          <h6><AiFillPhone />+971 58 820 9216</h6>
          <div className="info">
            <div>
            </div>
          </div>
        </div>
        <p id='copy'>Copyright ©2023 Yamco.com All rights reserved.</p>

      </footer>


    </div>
  )
}

export default Myfooter