
import './Home.css'
import Navbar from './Navbar'
import CounterUpPage from './CounterUpPage'
import Myfooter from './Myfooter'
import { BsBookmarkFill } from 'react-icons/bs'
import { FaBullseye } from 'react-icons/fa'
import { FaChartPie } from 'react-icons/fa'
import abtimg from '../images/abtimg.jpg'
import eventimg from '../images/event.png'
import enterimg from '../images/entertainment.png'
import markimg from '../images/marketing.jpg'
import digimg from '../images/digital.png'
import { NavLink } from 'react-router-dom'
import { BiAnalyse } from 'react-icons/bi'
import { FaRegHandshake } from 'react-icons/fa'
import { MdTipsAndUpdates } from 'react-icons/md'
import { AiOutlineFileDone } from 'react-icons/ai'

import { HiArrowCircleDown } from 'react-icons/hi'
import { HiArrowCircleUp } from 'react-icons/hi'

import { useState } from 'react';

const Home = () => {
    const [readMore, setReadMore] = useState(false);
    const [readMission, setReadMission] = useState(false);
    const [readVission, setReadVission] = useState(false);
    const [readValue, setReadValue] = useState(false);
    const extraContent = <div>
        <p className="extra-content">
            <p>Experience:  Our company has a proven track record of success, with years of experience helping businesses achieve their marketing goals.</p>
            <p>Results-driven:  Our company is focused on delivering measurable results for clients, such as increased website traffic, higher engagement rates, and more sales.</p>
            <p>Cutting-edge technology:   Our company uses the latest technology and tools to create effective marketing campaigns and stay ahead of the competition.</p>
        </p>
    </div>
    const linkName = readMore ? 'Read Less << ' : 'Read More >> '

    const linkMission = readMission ? <HiArrowCircleUp /> : < HiArrowCircleDown />
    const mission = <div>
        <span className='mission-mobile' >To facilitate and inspire brands into becoming consumer’s number one preference brand in the  region. To lead building high quality content for branding and entertainment.</span>
    </div>
    const linkVission = readVission ? <HiArrowCircleUp /> : < HiArrowCircleDown />
    const vission = <div>
        <span className='vission-mobile'>To be the number one preference for brands, consumers, and production companies in the  region for all content and branding requirements.</span>
    </div>
    const linkValue = readValue ? <HiArrowCircleUp /> : < HiArrowCircleDown />
    const value = <div>
        <span className='value-mobile'>To be the number one preference for brands, consumers, and production companies in the  region for all content and branding requirements.</span>
    </div>


    return (
        <div>

            <section className='header_main'>
                <Navbar />
                <div>

                    <div className='header__container'>

                        <h1 className='header_heading' >We Are</h1>
                        <h2 className='co_content'>Marketing & Advertising CO.</h2>
                        <p className='header_para'>We are an ingenious Marketing & Advertising Company, established in 2021 with a sense of modern apothecary and a database of several entertainers,
                            fashion models, make-up artists, promoters and performers.</p>
                        <div> <NavLink to="/contact">
                            <button className='chat_btn'> Let's Chat</button>
                        </NavLink>
                        </div>
                    </div>
                </div>
            </section>
            <section className='second_container'>

                <div className='second_container_boxes'>
                    <p id='scd_con_aims' >Aims</p>
                    <p id='scd_con_our' >Our</p>
                    <div id='scnd_container_box'>
                        <h3 id='scnd_boxes_names'> <BsBookmarkFill />Mission  <a className="read-more-link" onClick={() => { setReadMission(!readMission) }}> <h6>{linkMission}</h6> </a> </h3>
                        {readMission && mission}

                        <p>To facilitate and inspire brands into becoming consumer’s number one preference brand in the  region. To lead building high quality content for branding and entertainment.</p>


                    </div>
                    <div id='scnd_container_box'>
                        <h3 id='scnd_boxes_names'> <FaBullseye /> Vission <a className="read-more-link" onClick={() => { setReadVission(!readVission) }}> <h6>{linkVission}</h6> </a></h3>
                        {readVission && vission}

                        <p>To be the number one preference for brands, consumers, and production companies in the  region for all content and branding requirements.</p>
                    </div>
                    <div id='scnd_container_box'>
                        <h3 id='scnd_boxes_names'> <FaChartPie /> Value <a className="read-more-link" onClick={() => { setReadValue(!readValue) }}> <h6>{linkValue}</h6> </a></h3>
                        {readValue && value}

                        <p>We are honest and are brutally obsessed with the consumer. We aim to exceed excellence and  build brands for the future. We like to have fun in what we do.</p>
                    </div>
                </div>
            </section>
            <section className="third_container_about">
                <div className="about_us_container">

                    <img id='abtimg_third_con' src={abtimg} alt="" />

                    <div className="content">
                        <h1 id='third_con_heading'>Stuff About Us</h1>
                        <p>We are an ingenious Marketing & Advertising Company, established in 2021 with a sense of modern apothecary and a database of several entertainers, fashion models, make-up artists, promoters and performers.</p>
                        <NavLink to="/about">
                            <button id='third_con_btn'> Learn More</button>
                        </NavLink>
                    </div>
                </div>
            </section>
            <section className="fifth_container">
                <div className="bfr_work">
                    <div className="work">
                        <h3>How We Work</h3>
                        <h5>4 Steps to make Business successful</h5>
                        <div className='steps_cont'>
                            <div id='steps'>
                                <i> <BiAnalyse /> </i>
                                <h5>Analyze</h5>
                            </div>
                            <div id='steps'>
                                <i> < FaRegHandshake /> </i>
                                <h5>Advise</h5>
                            </div>
                            <div id='steps'>
                                <i> < MdTipsAndUpdates /> </i>
                                <h5>Strategy</h5>
                            </div>
                            <div id='steps'>
                                <i> < AiOutlineFileDone /> </i>
                                <h5>Result</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="forth_container_services">

                <div className="services_container">
                    <h4 id='forth_cont_line'>We work for your best experiences</h4>
                    <h1 id='forth_con_heading'>Our Services</h1>

                    <div className="forth_con_boxes"  >
                        <a className='atag_forthcon' href={'/Services'}> <div className="forth_con_box">

                            <div className="forth_con_content">
                                <div>
                                    <img id='forth_con_imgs' src={eventimg} alt="" />
                                </div>
                                <div>
                                    <h3>Events & Exhibitions</h3>

                                    <p>Corporate Event and Brand Launches, Roadshows, Fabrications,  Entertainment Events, Production, and Artist Management...p</p>
                                </div>
                            </div>
                            <div className="forth_con_content">
                                <div>
                                    <img id='forth_con_imgs' src={markimg} alt="" />
                                </div>
                                <div>
                                    <h3>Marketing & PR</h3>
                                    <p>Brand Strategy and Direction, Brand Activations, Shopper and Trade Marketing, Promotions and Sampling. Press release and...</p>
                                </div>
                            </div>


                            <div className="forth_con_content">
                                <div>
                                    <img id='forth_con_imgs' src={enterimg} alt="" />
                                </div>
                                <div>
                                    <h3>Entertainments Arts</h3>
                                    <p>Artist and Model Sourcing, Production Design and Execution, Make Up artists and Stylists, writers and content creators... </p>
                                </div>
                            </div>

                            <div className="forth_con_content">
                                <div>
                                    <img id='forth_con_imgs' src={digimg} alt="" />
                                </div>
                                <div>
                                    <h3>Digital Services</h3>
                                    <p>Make your campaign a digital success with our Inﬂuencer marketing, blogger management and media planning...</p>
                                </div>
                            </div>
                        </div>
                        </a>
                        <NavLink to="/services">
                            <button className='btn_ser' >Learn More</button>
                        </NavLink>

                    </div>
                </div>
            </section >
            <section className='sixth_container'>

                <CounterUpPage />

            </section>
            <section className="seventh_container">
                <div className="inner_container7">
                    <div id='choose' >
                        <h1>Why Choose Us?</h1>
                    </div>
                    <div id='points'>
                        <p>Experience:  Our company has a proven track record of success, with years of experience helping businesses achieve their marketing goals.</p>
                        <p>Results-driven:  Our company is focused on delivering measurable results for clients, such as increased website traffic, higher engagement rates, and more sales.</p>
                        <p>Cutting-edge technology:   Our company uses the latest technology and tools to create effective marketing campaigns and stay ahead of the competition.</p>
                    </div>
                    {readMore && extraContent}
                    <a className="read-more-link" onClick={() => { setReadMore(!readMore) }}><h6>{linkName}</h6></a>



                </div>
            </section>
            <Myfooter />

        </div >

    )
}

export default Home