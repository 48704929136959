import React from 'react'
import './Contact.css'
import Navbar from './Navbar'
import { FiMail } from 'react-icons/fi'
import { HiLocationMarker } from 'react-icons/hi'
import { FiPhoneCall } from 'react-icons/fi'
import { AiFillInstagram } from 'react-icons/ai'
import { BsTwitter } from 'react-icons/bs'
import emailjs from '@emailjs/browser'
import { FiDribbble } from 'react-icons/fi'
import { useRef } from 'react';


import Myfooter from './Myfooter'



function Contact() {
  const form = useRef();
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm("service_7xubz7w", "template_a1u809l", form.current, "vJ-6MaOezK2vKA8aa")
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  function myalert() {
    alert("Submitted! We will get in touch with you at a later time.");
  }




  return (
    <div className='contact_page'>

      <div className='contact_first'>
        <Navbar />

        <h1 id='contact_heading' >CONTACT</h1>

      </div>

      <div className='contact_second'>
        <h5 id='get_in_touch'>~Get in touch with us~</h5>
        <div className='second_con_box'>
          <div id='inside_sec_con_box1'>
            <FiMail />
            <h3>Email</h3>
            <h6>sales@yammco.com</h6>
            <a id='links_con_sec' href="mailto:sales@yammco.com"> Mail us </a>

          </div>
          <div id='inside_sec_con_box1'>
            <HiLocationMarker />
            <h3>Visit</h3>
            <h6>Vesla Motors Building - E 11, Exit - 40 Sheikh Zayed Rd -  Dubai</h6>
          </div>
          <div id='inside_sec_con_box3'>
            <FiPhoneCall />
            <h3>Phone</h3>
            <h6>+971 58 820 9216</h6>

            <a id='links_con_sec' href="tel:+971588209216">Call us</a>
          </div>

        </div>

      </div>

      <div className='contact_section_form'>
        <h2> <span id='contact_name'>Contact</span> Us</h2>
        <h5>Please do not hesitate to send us a message</h5>

        <form onSubmit={sendEmail} ref={form}  >

          <input name='user-name'
            placeholder='Your name'
            autoComplete='off'

          />
          <input type="email"
            name='user-email'
            placeholder='Your email'
            required

          />
          <textarea
            name="message"
            type="text" cols="30"
            rows="10"

            required placeholder='Type in here'
          ></textarea>
          <button value="Send"
            id='submit_button'
            type='submit'
            onClick={myalert}
          >Submit</button>

        </form>
      </div>
      <div id='social_icons'>
        Connect with us
        <div id='icons'>

          <a href="https://www.instagram.com/y.am.co/"><AiFillInstagram style={{ color: '#ff1744' }} /></a>
          <a href="https://twitter.com/YAM__company" ><BsTwitter style={{ color: "#ff1744" }} /></a>
          <a href="https://dribbble.com/YAM_co"><FiDribbble style={{ color: '#ff1744' }} /></a>
        </div>

      </div>
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.1227588609513!2d55.213790020623804!3d25.131540273616984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b8ea4b462b3%3A0xda9fde6dfd40e82!2sVESLA%20CERTIFIED%20PRE-OWNED%20MOTORS%20L.L.C!5e0!3m2!1sen!2s!4v1688486458314!5m2!1sen!2s"
          width="100%"
          height="400"
          style={{ border: "0" }}
          loading="lazy" referrerpolicy="no-referrer-when-downgrade">

        </iframe>
      </div>

      <Myfooter />
    </div >


  )
}

export default Contact

