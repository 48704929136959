import React from 'react'
import Navbar from './Navbar'
import { AiFillSketchCircle } from 'react-icons/ai'
import { TbBulbFilled } from 'react-icons/tb'
import { AiFillSetting } from 'react-icons/ai'
import { RiMoneyDollarCircleFill } from 'react-icons/ri'
import { BsFillClipboard2CheckFill } from 'react-icons/bs'
import { BsBookmarkFill } from 'react-icons/bs'
import { FaBullseye } from 'react-icons/fa'
import { FaChartPie } from 'react-icons/fa'
import Man from '../images/man.jpg'

import Myfooter from './Myfooter'
import './About.css'
const About = () => {
  return (
    <div>
      <div className="about_first">
        <Navbar />
        <h1 id='about_heading' >ABOUT</h1>
      </div>
      <div className="about_second">
        <div id='about-inner'>
          <h6 id='h6-heading' >Welcome To Our Community</h6>
          <h3 id='h3-heading' >  Top Consulting Agency</h3>
          <p id='about-para' >We are an ingenious Marketing & Advertising Company, established in 2021 with a sense of modern apothecary and a database of several entertainers, fashion models, make-up artists, promoters and performers. Our wide array of services range from Marketing and Event Management for promotional , corporate and brand events to Digital Inﬂuencing for social media marketing and Fashion Production for artists, stylists and creators.</p>
        </div>
      </div>
      <div className="about-third">
        <div id="six-boxes">
          <div className="box1" id='boxx'>
            <h3><AiFillSketchCircle /></h3>
            <h6>Good Approach</h6>
          </div>
          <div className="box1" id='boxx'>
            <h3><TbBulbFilled /></h3>
            <h6>Great Ideas</h6>
          </div>
          <div className="box1" id='boxx'>
            <h3><AiFillSetting className='icn' /></h3>
            <h6>Costumization</h6>
          </div>
          <div className="box1" id='boxx'>
            <h3><RiMoneyDollarCircleFill className='icn' /></h3>
            <h6>Save Money</h6>
          </div>
          <div className="box6" id='boxx'>
            <h3><BsFillClipboard2CheckFill className='icn' /></h3>
            <h6>Detailed Report</h6>
          </div>
        </div>
      </div>
      <div className="about-forth">

        <div className="forth-inner">

          <div className="innner">

            <div className="forth-con-para">
              <h6 id='what' >-What We Do</h6>
              <h3 id='how' >How We Can Help</h3>
              <p>Being a strategic marketing and advertising company, we ceaselessly work on the betterment of our working process. We can assist you with almost all your Marketing, PR, Exhibitions and Production needs. Be it a limited business meeting or a fashion gala, we’ve got you covered!</p>
              <p>Our well-acclaimed team of digital Inﬂuencers, Make Up artists, Stylists, writers, content creators, casting, and line producers can tackle almost any event, from the smallest of gatherings to biggest of jamborees.</p>
              <p>Our event-heads and inspection team are always around leaving no stone unturned in order to assure the best possible experience in bringing your event to life!Our clients can very well rely on us for stable support and surveillance at any given point in time. Our professional services would never leave the clients disappointed.</p>


            </div>
            <div>
              <img id='man-image' src={Man} alt="" />
            </div>
          </div>

        </div>
      </div>
      <div className="about-aims">
        <h2 id='yamcoaims' >YAMCO AIMS</h2>
        <div className="inner4-aims">
          <div id='aim_box'>
            <h3 id='aim_names'> <BsBookmarkFill /> Mission</h3>
            <p>

              To facilitate and inspire brands into becoming consumer’s
              number one preference brand in the  region. To lead building
              high quality content for branding and entertainment.
            </p>
          </div>
          <div id='aim_box'>
            <h3 id='aim_names'> <FaBullseye /> Vission</h3>
            <p>To be the number one preference for brands, consumers, and production companies in the  region for all content and branding requirements.</p>
          </div>
          <div id='aim_box'>
            <h3 id='aim_boxes_names'> <FaChartPie /> Value</h3>
            <p>We are honest and are brutally obsessed with the consumer. We aim to exceed excellence and  build brands for the future. We like to have fun in what we do.</p>
          </div>
        </div>
      </div>
      <Myfooter />
    </div>
  )
}

export default About